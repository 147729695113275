<template>
  <div>
    <h1 class="login_small_title_wrap">회원 계정 찾기</h1>
    <form class="k-form">
      <a @click="active=0" :class='mtab_id' style="cursor: pointer;">아이디 찾기</a>
      <a @click="active=2" :class='mtab_pw' style="cursor: pointer;">비밀번호 변경</a>
      <component :is="whichStep" :param="param" @child="next"></component>
    </form>
  </div>
</template>

<script>
  export default {
    name: 'FindAccount',
    components: { // 여기서 동적으로 로딩한다
      'FindId': () => import('./FindId'),
      'FindIdComplete': () => import('./FindIdComplete'),
      'FindPasswordStep1': () => import('./FindPasswordStep1'),
      'FindPasswordStep2': () => import('./FindPasswordStep2'),
      'FindPasswordStep3': () => import('./FindPasswordStep3'),
      'FindPasswordComplete': () => import('./FindPasswordComplete'),
    },
    props: {
      prop_active: {
        type: Number,
        default : 0,
        validator: function(value) {
          return value === 0 || value === 2
        }
      }
    },
    created () {
      this.active = this.prop_active
    },
    computed: {
      whichStep () {
        switch (this.active) {
          case 0:
            this.mtab_id = 'tabon'
            this.mtab_pw = 'taboff'
            return 'FindId'
          case 1:
            this.mtab_id = 'tabon'
            this.mtab_pw = 'taboff'
            return 'FindIdComplete'
          case 2:
            this.mtab_id = 'taboff'
            this.mtab_pw = 'tabon'
            return 'FindPasswordStep1'
          case 3:
            this.mtab_id = 'taboff'
            this.mtab_pw = 'tabon'
            return 'FindPasswordStep2'
          case 4:
            this.mtab_id = 'taboff'
            this.mtab_pw = 'tabon'
            return 'FindPasswordStep3'
          case 5:
            this.title = '비밀번호 재설정 완료'
            return 'FindPasswordComplete'
          default:
            this.mtab_id = 'tabon'
            this.mtab_pw = 'taboff'
            return 'FindId'
        }
      },
    },
    methods: {
      next (resultData) {

        if (this.active++ > 4) {this.active = 0}
        if (resultData != null) {this.param = resultData}
      },
    },
    data () {
      return {
        active: 0,
        title: '회원계정 찾기',
        mtab_id: 'tabon',
        mtab_pw: 'taboff',
        param: null,
      }
    },
  }
</script>

<style scoped>
</style>
